<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="w-full fill-los-yellow-base"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <clipPath id="34c85505f4">
        <path
          d="M 37.5 112.195312 L 337.5 112.195312 L 337.5 262.945312 L 37.5 262.945312 Z M 37.5 112.195312 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#34c85505f4)">
      <path
        d="M 337.386719 112.195312 L 337.386719 112.90625 C 337.386719 133.136719 333.421875 152.765625 325.605469 171.246094 C 318.058594 189.09375 307.25 205.121094 293.484375 218.886719 C 279.71875 232.652344 263.691406 243.457031 245.84375 251.007812 C 227.363281 258.824219 207.738281 262.785156 187.503906 262.785156 C 167.269531 262.785156 147.640625 258.824219 129.160156 251.007812 C 111.3125 243.457031 95.285156 232.652344 81.519531 218.886719 C 67.753906 205.121094 56.949219 189.09375 49.398438 171.246094 C 41.582031 152.765625 37.621094 133.136719 37.621094 112.90625 L 37.621094 112.195312 Z M 337.386719 112.195312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
