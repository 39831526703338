<template>
  <div class="w-full relative hidden md:flex items-center">
    <div
      class="absolute bottom-0 translate-y-1/2 h-10 max-w-xs w-full"
      :style="{
        maskPosition: 'center left',
        maskSize: 'calc(100% / 10) 100%',
        maskImage: 'url(/pattern/kruissteek.svg)',
        maskRepeat: 'repeat-x',
      }"
      :class="[patternClass, position]"
    ></div>
  </div>
</template>

<script setup>
defineProps({
  patternClass: {
    type: String,
    default: 'bg-los-blue-light',
  },
  position: {
    type: String,
    default: 'left-0',
  },
});
</script>
