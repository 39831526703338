<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="w-full fill-los-pink-base"
  >
    <defs>
      <clipPath id="94d6188486">
        <path
          d="M 37.5 57.5625 L 337.5 57.5625 L 337.5 317.8125 L 37.5 317.8125 Z M 37.5 57.5625 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#94d6188486)">
      <path
        d="M 37.5 317.402344 L 187.5 57.597656 L 337.5 317.402344 Z M 37.5 317.402344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
