<template>
  <div class="w-full relative">
    <div
      class="absolute left-1/2 -translate-y-1/2 -translate-x-[2px] h-[84px] border-l-4 border-dashed border-los-pink-base"
    ></div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
